.container {
  height: 100vh;
  width: 100vw;
}

.CelebTicket {
  position: relative;
  background-color: white;
}

.fourthPart {
  width: 100%;
  background-color: #df0c62;
  padding: 48px 0 8px 0;
}

.yellowBackground {
  width: 100%;
  background-color: #fff0d7;
  padding: 0 16px 0 16px;
  position: relative;
}

.bottomLogo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 0;
}

.emptyBox {
  width: 100%;
  height: 10rem;
  position: relative;
}
.stage {
  width: 100%;
  position: absolute;
  bottom: -80%;
  z-index: 1;
}

.cloud {
  position: absolute;
  bottom: -50%;
  left: 14%;
  z-index: 1;
  width: 14.5rem;
}

/* bottom nav */
.navBar {
  width: 100vw;
  height: 3rem;
  background-color: #df0c62;
  position: fixed;
  bottom: 0;
  z-index: 1;
}
