@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
}

.container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.eventHeader {
  background-color: #ffd283;
  height: 40%;
  padding-top: 8%;
}

.eventPoster {
  width: 100%;
  box-shadow: -5px 0 0 0 #df0c62;
}

.eventDetails {
  height: 100%;
  padding: 5% 32px 32px;
}

.eventTicket {
  width: 40%;
  height: 35%;
}

.redDash {
  width: 100%;
  border-bottom: 2px dashed #df0c62;
}

.middleSection {
  margin-top: 7%;
  position: relative;
}

@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
  letter-spacing: 8px;
}

.container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.eventHeader {
  background-color: #ffd283;
  height: 40%;
  padding-top: 8%;

}

.eventStack{
  flex-direction: row!important;
}

.eventPoster {
  width: 100%;
  box-shadow: -5px 0 0 0 #df0c62;
}

.eventDetails {
  height: 100%;
  padding: 5% 32px 32px;
}

.redDash {
  width: 100%;
  border-bottom: 2px dashed #df0c62;
}

.eventTicket {
  width: 40%;
  height: 35%;
}

.middleSection {
  padding: 32px;
  margin-top: 250px;
  position: relative;
}

.yellowLine {
  border-bottom: 2px solid#ffd283;
}



@media only screen and (max-width: 600px) {

  .buyTicketContainer{
    position: relative;
    width: 100%;
    height: fit-content;
    left: 0%;
    bottom: 30px;
  }

  .eventPageContainer{
    height:fit-content;
  }

  .eventHeader{
    height:fit-content;
    background-color: #ffd283;
    padding-top: 80px;
  }

  .eventStack{
    flex-direction: column!important;
  }

  .middleSection{
    padding: 10px;
    margin: 0;
    overflow-y: hidden;
  }

}
