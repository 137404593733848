/* NavBar */
.navBar {
  position: fixed;
  width: 100vw;
  z-index: 10;
}

.mobileBox {
  width: 100vw;
  height: 60px;
  border-bottom-left-radius: 100% 25%;
  border-bottom-right-radius: 100% 25%;
}
