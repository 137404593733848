@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
}

.Admin {
  background-color: #feb659 !important;
  box-shadow: 0px 0px 5px 3px #fe0c62 !important;
}

.Customer {
  background-color: #feb659 !important;
  box-shadow: 0px 0px 5px 3px #fe0c62 !important;
}

.Organizer {
  background-color: #feb659 !important;
  box-shadow: 0px 0px 5px 3px #fe0c62 !important;
}
