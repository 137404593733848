.seatPickerOuter {
  overflow: hidden;
  position: relative;
  max-height: 900px;
}

.sc-main-container {
  overflow: scroll;
  height: 900px;
}

.sc-map {
  overflow: scroll;
  width: calc(100% - 340px);
}

.sc-right-container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px 20px;
  background: #ffd283;
}

.sc-cart {
  margin-bottom: 0 !important;
}

.sc-cart-btn-submit {
  display: none;
}

.sc-seat {
  cursor: pointer;
}

.planBox .sc-seat.sc-seat-selected {
  opacity: 0.3;
}

.a-class {
  background: #005997;
}
.b-class {
  background: #b14762;
}
.c-class {
  background: #d1ab14;
}
.d-class {
  background: #666666;
}
.e-class {
  background: #ffd283;
}
.f-class {
  background: #545a46;
}

.g-class {
  background: #64ccc5;
}
.h-class {
  background: #da0c81;
}
.i-class {
  background: #de8f5f;
}
.j-class {
  background: #005b41;
}
.k-class {
  background: #cdfad5;
}

.l-class {
  background: #ffcd4b;
}
.m-class {
  background: #ff7676;
}
.n-class {
  background: #b5cb99;
}
.o-class {
  background: #f1b4bb;
}
.p-class {
  background: #a6f6ff;
}

.no-class {
  background: #737373;
}

.color-picking {
  height: 20px;
}

.color-picking:hover {
  transition: all 0.2s;
  transform: scale(1.1);
}

.first-class {
  background: #005997;
}
.second-class {
  background: #b14762;
}
.third-class {
  background: #d1ab14;
}
.fourth-class {
  background: #666666;
}
.fifth-class {
  background: #ffd283;
}

.zoomBox {
  width: calc(100% - 340px);

  padding: 6px 30px;
}

.zoomBox {
  display: block;
  text-align: right;
}

.zoomBox div {
  background: #ffd283;
  padding: 2px 10px;
  border-radius: 3px;
  margin-left: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-bottom: 10px;
}

.inverted .sc-seat-row {
  flex-direction: row-reverse;
}

.imageModal {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 90%;
}

.imageModal img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}

.eventPageContainer .sc-seat-row {
  align-items: center;
  justify-content: center;
}

.eventPageContainer .sc-spacer {
}

.eventPageContainer *::-webkit-scrollbar {
  display: none;
}

.eventPageContainer * {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.eventPageContainer .sc-indexer,
.eventPageContainer .sc-indexer,
.eventPageContainer .sc-seat.sc-seat-disabled {
}

.hotSalePage .sc-seat-row {
  align-items: center;
  justify-content: center;
}

.hotSalePage .sc-spacer {
  /* height:0; */
}

.hotSalePage .sc-indexer,
.hotSalePage .sc-indexer,
.hotSalePage .sc-seat.sc-seat-disabled {
  display: none;
}

.SeatingBox .sc-main-container {
  height: fit-content;
  overflow: auto;
}

.SeatingBox .sc-map {
  /* height: 0px; */
  overflow: auto;
  justify-content: flex-start;
  align-items: flex-start;
}

.eventPageContainer .sc-map-inner-container,
.eventPageContainer .sc-indexer {
  transition: transform 0.5s ease;
}

/* .sc-seat.reserved-by-admin {
  background-color: black !important;
  color: white !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
  pointer-events: none;
}

.sc-seat.reserved-by-admin a-class,
.sc-seat.sc-seat-available.reserved-by-admin {
  background-color: black !important;
  color: white !important;
} */
