a{
  text-decoration: none!important;
  color:inherit;
}

a:hover, a:focus, a:active{
  text-decoration: none!important;
  color:inherit;
}

.MuiAccordion-root{
  box-shadow: unset!important;
}

.disabledDiv{
  opacity: 0.5;
  pointer-events: none;
}

.planImageBox img{
  object-fit: contain;
}

.simpleInput{
  padding: 3px 8px;
    text-align: center;
    background: transparent;
    border: none;
    border-bottom: 2px solid #feb659;
    color: #eb6287;
    font-weight: 600;
    outline-color: #feb659!important;
}

/* .simpleInput:focus, .simpleInput:active, .simpleInput:focus-visible, .simpleInput:focus-within{
  outline: #feb659
} */

.Toastify__progress-bar--success {
    background: #feb659;
}

:root {
  --toastify-icon-color-success: #feb659;
  --toastify-progress-bar-success: #feb659;
  --toastify-icon-color-error: #df0c62;
  --toastify-progress-bar-error: #df0c62;
  --toastify-icon-color-warning: #df0c62;
  --toastify-progress-bar-warning: #df0c62;
}

.letterSFive{
  letter-spacing: 5px;
}

.ticketPage > div{height:100%;}
.ticketPage > div > div{height:100%;}
.ticketPage > div > div > div{height:100%;}

.ticketsModal{
  height: 100%;
  width: 100%;
  overflow: auto;
  padding:100px;
}

.ticketsModal .celebTicket{
  position: relative;
  background-color: white;
}

.ticketsModal .celebTicket svg{
  width:150px;
  height:150px;
  position: absolute;
  top: 0;
  bottom:0;
  right: 100px;
  margin: auto 0;
}

.colorPicker{
  padding:3px!important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0;
  grid-auto-rows: minmax(50px, auto);
  height: 0;
  width:0;
  transition: all .5s ease;
  position: absolute;
  bottom: -250px;
  left: 0px;
  overflow: hidden;
  background: #fff;
  z-index: 1000;
}

.colorPicker.open{
  height: 305px;
  width:156px;
}

/* .colorPickerItem{
  padding:0!important;
  border: 2px solid transparent;
} */

/* .colorPickerItem.picked{
  padding:0!important;
  border: 2px solid #df0b62;
} */

.centeredFlex{
  display:flex;
  align-items:center;
  justify-content:center;
}

.quantityBut{
  border:1px solid #df0b62!important;
  color: #df0b62!important;
  border-radius: 2px;
}

.hiddenframe{
  display: block;
  width:100%;
  height:0;
  padding:0;
  margin:0;
  border:none;
  transition: all 1s ease;
}

.openframe{
  height:300px;
  transition: all 1s ease;
}

.ticket{
      background-position: left;
      margin-bottom:30px;
}

.ticket .logo{

    width: 140px!important;
    transform: rotate(-90deg);
    position: relative;
    right: 30%!important;

}

.qrticket{
  position: absolute;
  right:15px;
  top: 0;
  bottom:0;
  margin:auto!important;
  padding:3px;
  height: fit-content;
  width: fit-content;
  background:white;
}

.qrticket svg{
  width:100px;
  height:100px;
}

.quantityBut:hover{
  background-color: #ffd283!important;
}

.cursor-pointer{
  cursor:pointer;
}

.counter-account-box input{
  border:none;
  background: transparent;
  border-bottom: 1px solid #df0b62;
  color: #df0b62;
  width: 100%;
  height:100%;
}

.modalFormInput{
  width: 48%;
  margin-left: 1%;
  border: none;
  border-bottom: solid 2px #ffd283;
  background-color: transparent;
  color: #df0c62;
  padding: 15px 10px;
  letter-spacing: 3px;
  display: inline-block;
}

.modalFormInput fieldset{
  border: none;
  box-shadow: none;
  outline: none;
  border-bottom: none!important;
}

.modalFormInput div{
  padding:0!important;
}

.modalFormInput input{
  border-bottom: none!important;
  padding:0!important;
  margin:0!important;
}

#payment-form {
  border: #df0c62 solid 2px;
  padding: 20px;
}

#payment-form iframe .Tab--selected{
  box-shadow: none!important;
  border: 2px solid #df0c62!important;
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0A253C;
  color: #00D924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size:0.7em;
}


.planBox .zoomBox{
  width:100%;
}

.planBox .sc-map{
  width:100%;
}

.planBox .sc-main-container{
  padding:0;
  height:650px;
}

.planBox .sc-right-container{
  display: none;
}


.disabledListItem{
  opacity: 0.3;
  pointer-events: none;
}

.disabledListItem:after{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50px;
  content: "Doğrulanmadı";
  display: flex;
  align-items: center;
  justify-content: end;
  width: fit-content;
}

.loadingBut{
  opacity:0.4!important;
  cursor:wait!important;
}



@media only screen and (max-width: 768px) {

  .buyTicketContainer{
    position: relative;
    width: 100%;
    height: 30px;
    left: 0%;
    bottom: 40px;
  }

  .zoomBox{
    width:100%;
    padding: 0;
  }


  .eventPageContainer .seatPickerOuter{
    height:fit-content;
    max-height: unset;
  }

  .eventPageContainer .sc-main-container{
    position: relative;
    flex-direction: column;
    height: fit-content;
    padding: 0;
  }

  .eventPageContainer .sc-map{
    width: 100%;
    align-items: end;
  }

  .eventPageContainer .sc-right-container{
    width:100%;
    margin-left: 0;
    position: relative;
    padding-bottom: 40px;
  }

  .eventPageContainer .sc-cart{
    width:100%;
  }

  .eventPageContainer .sc-cart-table-container{
    height:fit-content;
  }

}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
