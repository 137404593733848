.search {
  background-color: "#fcf2e2";
  border: "none";
  flex-grow: 1;
}

.search:focus {
  outline: none;
}

/* .textStyle:hover {
  color: #df0c62;
  text-decoration: underline;
} */

.textStyle {
  background-image: linear-gradient(to right, #df0c62, #df0c62 50%, white 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.textStyle:before {
  content: "";
  background: #df0c62;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.textStyle:hover {
  background-position: 0;
}

.textStyle:hover::before {
  width: 100%;
}
