.innerCircle {
  width: 70%;
  height: 70%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.circleBase {
  border-radius: 50%;
}

.circle2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  border: none;
  background-image: linear-gradient(
    45deg,
    hsl(335deg 90% 46%) 0%,
    hsl(349deg 89% 58%) 33%,
    hsl(4deg 100% 64%) 48%,
    hsl(17deg 100% 61%) 60%,
    hsl(30deg 100% 57%) 69%,
    hsl(41deg 100% 51%) 79%,
    hsl(48deg 100% 50%) 88%,
    hsl(55deg 100% 50%) 100%
  );
}

.colorBox {
  border: 2px solid #ffea00;
  padding: 50px;
  background-image: linear-gradient(
    65deg,
    hsl(55deg 100% 50%) 0%,
    hsl(32deg 97% 49%) 19%,
    hsl(10deg 94% 48%) 26%,
    hsl(347deg 92% 46%) 32%,
    hsl(335deg 90% 39%) 37%,
    hsl(336deg 90% 26%) 41%,
    hsl(335deg 91% 13%) 45%,
    hsl(0deg 0% 0%) 50%,
    hsl(335deg 91% 13%) 54%,
    hsl(336deg 90% 26%) 59%,
    hsl(335deg 90% 39%) 64%,
    hsl(335deg 90% 39%) 70%,
    hsl(336deg 90% 26%) 78%,
    hsl(335deg 91% 13%) 87%,
    hsl(0deg 0% 0%) 100%
  );
}
