.endOfPage {
  background-color: #df0c62;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 8px;
  position: relative;
  overflow: hidden;
}

.endOfPageColumn {
  background-color: #fff0d7;
}

.logo {
  width: 50%;
}

.stage {
  width: 115%;
  fill: #df0c62;
  position: absolute;
  z-index: 0;
  top: 8%;
}

.cloud {
  width: 50%;
}

.bottomStage {
  /* width: 58%; */
  position: relative;
  /* left: 50.5%; */
  /* object-fit: ; */
  transform: translateY(-100px);
  
}
