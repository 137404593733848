.image2_wrapper {
  position: relative;
  width: 100%;
  /* The size you want */
}

.image2_wrapper:after {
  content: "";
  display: block;
  padding-bottom: 150%;
  /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.image2_wrapper img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
  border-radius: 5px;
}
