.submit {
  letter-spacing: 3px;
  font-size: 18px;
  color: #df0c62;
  height: 100%;
}

.formScroll::-webkit-scrollbar {
  width: 5px;
}

.formScroll::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}

.submit input {
  width: 48%;
  margin-left: 1%;
  border: none;
  border-bottom: solid 2px #ffd283;
  background-color: transparent;
  color: #df0c62;
  padding: 15px 10px;
  letter-spacing: 3px;
}

.submit input::placeholder {
  color: #df0c62;
  font-weight: 500;
}

.submit input:focus {
  outline: none;
}

.note {
  border-bottom: solid 2px #ffd283;
  text-align: center;
}

.button {
  width: 100%;
  background-color: #df0c62;
  border: none;
  color: #fff;
  padding: 10px;
  letter-spacing: 3px;
  font-weight: 500;
}

.button_disabled{
  width: 100%;
  background-color: #f56ca3;
  border: none;
  color: #fff;
  padding: 10px;
  letter-spacing: 3px;
  font-weight: 500;
}

.textArea::placeholder {
  color: #df0c62;
}
