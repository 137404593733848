@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slide {
  cursor: pointer;
  scale: 0.9;
  /* transform: scale(0.9); */
  box-shadow: 0px 0px 10px 5px #ff8dc9;
  transition: 750ms;
}

.slide:hover {
  /* transform: scale(0.9); */
  cursor: pointer;
  transition: 750ms;
  box-shadow: -10px 0px 0px 0px #df0c62;
}

.slide:focus {
  box-shadow: -10px 0px 0px 0px #df0c62;
  border: none;
  outline: none;
}

.activeSlide {
  cursor: pointer;
}

.activeSlide:hover {
  /* transform: scale(0.9); */
  cursor: pointer;
  box-shadow: -10px 0px 0px 0px #df0c62;
  transition: 750ms;
}

.arrow {
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.arrow svg:hover {
  color: #ffd289;
}
.arrow svg {
  transition: color 500ms;
}

.next {
  right: -5%;
  top: 50%;
}

.prev {
  left: -5%;
  top: 50%;
}
