@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
  font-family: "normalPhosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
  letter-spacing: 16px;
}

.buy {
  font-family: "Phosphate" !important;
}

.container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  padding-top: 100px;
}

.SearchTitle {
  letter-spacing: 8px !important;
}

.searchCountry {

  position: relative;
}

.searchCountry>div{
  background-color: #df0c62;
}

.arrow {
  height: 15%;
  width: 15%;
  fill: white;
  transform: rotate(180deg);
}

.city {
  fill: #df0c62;
  rotate: 180deg;
  position: absolute;
  height: 400%;
  width: 400%;
  left: -119.5%;
  top: -62%;
  z-index: -1;
}

.events {
  background-color: #fff0d7;
  padding: 16px 0;
}

.redDash {
  border-bottom: 4px dashed #df0c62;
}

.locationAndDate {
  height: 10rem;
  overflow-y: scroll;
}
.locationAndDate::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.locationAndDate::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}
.ticketContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  cursor:pointer;
}

.ticketContainer:hover,
.ticketContainer:focus {
  opacity: 0.8;
}
.ticketContainer:active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.buy {
  font-family: "Phosphate" !important;
  z-index: 1;
}

.smallTicket {
  width: 65%;
  height: 2rem;
  position: absolute;
  right: 0%;
}

.yellowLine {
  border-bottom: 1px solid #feb659;
}

.endOfPage {
  background-color: #df0c62;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 8px;
  position: relative;
  overflow: hidden;
}

.endOfPageColumn {
  background-color: #fff0d7;
}

.logo {
  width: 60%;
}

.stage {
  width: 115%;
  fill: #df0c62;
  position: absolute;
  z-index: 0;
  top: 5%;
}

.cloud {
  width: 80%;
  align-self: center;
}


.city {
  fill: #df0c62;
  rotate: 180deg;
  position: absolute;
  height: 400%;
  width: 400%;
  left: -119.5%;
  top: -62%;
  z-index: -1;
}

.cityImage{
  width: 320px;
  height: 52px;
  object-fit: cover;
  transform: rotate(180deg);
  position: relative;
  float: right;
  right: -55px;
}
