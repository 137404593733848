.buttonContainer {
  height: 100%;
  width: 100%;
  cursor: pointer;
  /* z-index: 9999 !important; */
  /* transition: opacity 0.1s ease; */
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.buttonContainer.true .top {
  transform: translateY(6.2px) translateX(0) rotate(45deg);
  /* background: #df0c62; */
  background-color: black;
}
.buttonContainer.true .middle {
  display: none;
  /* background: #df0c62; */
  background-color: black;
}
.buttonContainer.true .bottom {
  transform: translateY(-6.2px) translateX(0) rotate(-45deg);
  /* background: #df0c62; */
  background-color: black;
}

.buttonContainer span {
  background: white;
  border: none;
  height: 2.5px;
  width: 40px;

  top: 0;
  left: 0;

  transition: all 0.35s ease;
  cursor: pointer;
}

.buttonContainer span:nth-of-type(2) {
  top: 9px;
}
.buttonContainer span:nth-of-type(3) {
  top: 18px;
}

.textStyle {
  background-image: linear-gradient(to right, #df0c62, #df0c62 50%, white 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.5s ease-in-out;
}

.textStyle:before {
  content: "";
  background: #df0c62;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.5s ease-in-out;
}

.textStyle:hover {
  background-position: 0;
}

.textStyle:hover::before {
  width: 100%;
}
