.scroll {
  background-color: #fff0d7;
  height: 50vh;
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}
