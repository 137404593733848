.lastPart {
  position: relative;
  overflow: hidden;
}

.stage {
  fill: #fe0c62;
  position: absolute;
  width: 100%;
  height: 125%;
  top: 12%;
  right: -35%;
}

.cloud {
  width: 25%;
}

.member {
  overflow-y: scroll;
}

.member::-webkit-scrollbar {
  width: 5px;
}

.member::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}
