@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
}
.container {
  position: relative;
}

/* Top Logo */
.topBar {
  position: fixed;
  top: 0%;
  z-index: 2;
}

.mobileBox {
  width: 100vw;
  height: 60px;
  background: #ffd283;
}

.mobileCurve {
  width: 100vw;
  height: 10px;

  border-bottom-left-radius: 50% 100%;
  border-bottom-right-radius: 50% 100%;

  background: #ffd283;
  box-shadow: 0px 3px 0px 3px rgba(253, 201, 129, 0.702);
}

.navBar {
  width: 100vw;
  height: 3rem;
  background-color: #df0c62;
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.firstBox {
  background-color: #ffd283;
  padding: 0 16px;
  position: relative;
}

.redDash {
  width: 100%;
  border-bottom: 3px dashed #df0c62;
}

.EventPoster {
  position: absolute;
  bottom: -19.8%;
}

.EventPoster img {
  width: 95%;
  box-shadow: -8px 0 0 0 #df0c62;
}

.cart {
  border: 1px solid #df0c62;
  padding: 8px;
}

.yellowLine {
  width: 100%;
  border-bottom: 2px solid #feb659;
}

.yellowBorder {
  border-bottom: 2px solid #feb659;
}

.Odeme {
  border: 1px solid #df0c62;
  padding: 8px;
}

.input {
  width: 100%;
  border: 1px solid #df0c62;
  padding: 16px;
}

.fourthPart {
  width: 100%;
  background-color: #df0c62;
  padding: 48px 0 8px 0;
}

.yellowBackground {
  width: 100%;
  background-color: #fff0d7;
  padding: 0 16px 0 16px;
  position: relative;
}

.bottomLogo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 0;
}

.emptyBox {
  width: 100%;
  height: 10rem;
  position: relative;
}
.stage {
  width: 100%;
  position: absolute;
  bottom: -80%;
  z-index: 1;
}

.cloud {
  position: absolute;
  bottom: -50%;
  left: 14%;
  z-index: 1;
  width: 14.5rem;
}
.buyTicketContainer{
  position: relative;
  width: 100%;
  height: fit-content;
  left: 0%;
  bottom: 30px;
}

.eventPageContainer{
  height:fit-content;
}

.eventHeader {
  background-color: #ffd283;
  height: 40%;
  padding-top: 8%;

}

.eventStack{
  flex-direction: row!important;
}

.eventPoster {
  width: 100%;
  box-shadow: -5px 0 0 0 #df0c62;
}

.eventHeader{
  height:fit-content;
  background-color: #ffd283;
  padding-top: 80px;
}

.eventStack{
  flex-direction: column!important;
}

.middleSection{
  padding: 10px;
  margin: 0;
  overflow-y: hidden;
}


@media only screen and (max-width: 768px) {



}
