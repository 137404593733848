.scrollable {
  background-color: #fff0d7;
  max-height : 50vh;
  height: fit-content;
  overflow-y: scroll;
}

.scrollable::-webkit-scrollbar {
  width: 5px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}
