@font-face {
  font-family: "Phosphate";
  src: url("../../../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
  letter-spacing: 8px;
}

.topContainer {
  background-color: #fff0d7;
  box-shadow: inset 0px 1px 10px 0px #fec982;
  height: 300px;
  padding-top: 4%;
}

.eventPoster {
  width: 100%;
  box-shadow: -5px 0 0 0 #df0c62;
}

.eventDetails {
  height: 100%;
  padding: 5% 32px 32px;
}
.redDash {
  width: 100%;
  border-bottom: 2px dashed #df0c62;
}

/* Seat Image and Seat Picked */
.pickedSeats {
  background-color: #fff0d7;
  height: 38rem;
  overflow-y: scroll;
  background-color: #ffd283;
}
.pickedSeats::-webkit-scrollbar {
  width: 5px;
}

.pickedSeats::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}

.seat {
  background-color: #fff0d7;
  padding: 16px;
  position: relative;
}

.deleteButton {
  position: absolute;
  right: -5%;
  top: -25%;
}

.ticketStack::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.ticketStack::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: #df0c62;
}
