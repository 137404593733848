@font-face {
  font-family: "Phosphate";
  src: url("../../../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
}

.posterContainer {
  height: 707px;
  width: 466px;
  object-fit: cover;
}

.eventImage {
  width: 100%;
  height: 100%;
  box-shadow: -5px -5px 0px 0px #df0c62;
}
