@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
  font-family: "normalPhosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
  letter-spacing: 16px;
}

.searchTitle {
  letter-spacing: 4px !important;
}

.smallRedBox {
  background-color: #df0c62;
  color: white;
  position: relative;
}

.city {
  position: absolute;
  top: -115%;
  right: 0%;
  width: 50%;
  height: 800%;
  overflow: hidden;
  z-index: -1;
}

.city img {
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
  position: absolute;
  left: 19%;
  top: -16%;
  z-index: -1;
}

.eventsRow {
  background-color: #fff0d7;
  padding: 16px;
}

.dashedRed {
  width: "100%";
  border-top: noen;
  border-left: none;
  border-right: none;
  border-bottom: 3px dashed #df0c62;
  padding: 2% 0;
}

.eventsDates {
  overflow-y: scroll;
  height: 10rem;
}

.eventsDates::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}
.eventsDates::-webkit-scrollbar-thumb {
  background-color: #df0c62;
}

/* .smallTicket img {
  width: 6.5rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
} */
.buyContainer {
  position: relative;
}

.buyContainer:hover,
.buyContainer:focus {
  opacity: 0.8;
}
.buyContainer:active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.buyText {
  font-family: "Phosphate" !important;
  color: #df0c62;
  text-align: center;
  margin-right: 5%;
  position: relative;
  z-index: 1;
}
.smallTicket {
  position: absolute;
  width: 50%;
  height: 2rem;
  left: 28.5%;
  top: -10%;
  z-index: 0;
}

.ticketContainer {
  padding: 8px 0 2px 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #feb659;
}

.bottomStage {
  width: 100%;
}
