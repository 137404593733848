.barcode-screen {
  color: #df0c62;
  text-align: center;
  letter-spacing: 4px !important;
  justify-content: center;
  padding-bottom: 50px;
}


.barcode-screen-heading-primary {
  font-family: "Phosphate" !important;
  letter-spacing: 16px !important;
  margin-top: 100px !important;
  font-size: 22px !important;
}

.barcode-screen-heading-secondary {
  letter-spacing: 6px !important;
  font-size: 18px !important;
  margin-bottom: 5vh;
}

.barcode-screen-heading-tertiary{
  font-size: 18px !important;
  font-weight: bold;
  border-bottom: 2px solid #df0c62;
  display: inline-block;
  margin-bottom: 2vh;
}

.barcode-screen-info {
  font-size: 18px !important;
  margin-bottom: 4vh;
}

.barcode-screen-button{
  background-color: #df0c62;
  width: 95%;
  color:#fff;
  border: none;
  padding: 5px;
  /* position: absolute; */
  left: 1.5vh;
  bottom: 10vh;
}

.barcode-scanner{
  height: 100vh;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scanner{
  width: 50vw;
  height: 25vh;
  background-color: #bdbdbd;
  position: relative;
}

.scanner::after {
  content: "BARKOD OKUT";
  color: #df0c62;
  font-weight: bold;
  position: absolute;
  top: -3vh;
}

.fixedAlt{
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width:100%;
  height:50px;
  align-items: center;
  justify-content: center;
  background: white;
}
