@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
  font-family: "normalPhosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate.ttc");
}

.tio {
  font-family: "normalPhosphate";
  letter-spacing: 6px !important;
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
  letter-spacing: 32px !important;
}

.container {
  position: relative;
}

.firstPart {
  background-color: #ffd283;
  position: relative;
}

.carouselStack {
  position: relative;
  top: 5%;
}

.searchTitle {
  color: #df0c62;
  font-weight: bold !important;
  letter-spacing: 4px !important;
}

.searchCountry {
  background-color: #df0c62;
  position: relative;
}

.city {
  fill: #df0c62;
  rotate: 180deg;
  position: absolute;
  height: 700%;
  width: 700%;
  left: -264.5%;
  top: -185%;
  /* eslint-disable-next-line */
  z-index: -1;
}

.eventsList {
  background-color: #fff0d7;
  height: 30rem;
  overflow-x: visible;
  overflow-y: scroll;
  padding: 48px;

  border-radius: 12px;
  position: relative;
}
.eventsList::-webkit-scrollbar {
  width: 5px;
}

.eventsList::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}

.eventListPoster {
  width: 100%;
  cursor: pointer;
}

.subscribe {
  background-color: #feb659;
  width: 100%;
  height: 10rem;
  padding: 32px;
}

.focused {
  transition: all 0.25s ease-in-out;
  transform: scale(1.5);
  /* width: 75%; */
  box-shadow: -5px 0px 0px 0px #df0c62;
  margin-left: 5px;
  position: relative;
  z-index: 4;
}

.unfocused {
}

.buyContainer {
  position: relative;
}

.buyContainer:hover,
.buyContainer:focus {
  opacity: 0.8;
}
.buyContainer:active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  transition: 0.1s;
}

.buyText {
  font-family: "Phosphate" !important;
  color: #df0c62;
  text-align: center;
  margin-right: 5%;
  position: relative;
  z-index: 1;
}
.smallTicket {
  position: absolute;
  width: 60%;
  height: 2rem;
  left: 25.5%;
  top: -10%;
  z-index: 0;
}
.datesList {
  height: 10rem;
  overflow: scroll;
}

.datesList::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.datesList::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}

.datesList::-webkit-scrollbar-corner {
  background-color: transparent;
  border-radius: 25px;
}
