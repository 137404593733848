.templateOne {
  background-image: url("../../../../../Assets/Images/aidKit.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.templateTwo {
  background-image: url("../../../../../Assets/Images/rockStar.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.templateThree {
  background-image: url("../../../../../Assets/Images/warmCore.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.templateFour {
  background-image: url("../../../../../Assets/Images/headphoneSticker.png");
  background-repeat: no-repeat;
  background-size: cover;
}
