@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
  font-family: "normalPhosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate.ttc");
}

.tio {
  font-family: "normalPhosphate";
  letter-spacing: 6px !important;
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
  letter-spacing: 32px !important;
}

.focusedTitle {
  font-family: "Phosphate" !important;
}

.container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.featured {
  padding-top: 100px;
  /* background-color: #ffd283; */
  height: fit-content;
  padding-left: 20%;
  position: relative;
}

.featured #background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #ffd283;
  z-index: -1;
}

.featuredBox {
  background-color: #feb659;
  height: 50%;
  padding-left: 6.25%;
}

.featuredPosters {
  /* padding: 0 25%; */
  width: 100%;
  height: 100%;
  position: relative;
  left: -12.5%;
  top: 2.5%;
  /* display: flex;
  justify-content: center;
  gap: 2%; */
}

.featuredPosters img {
  /* width: 15%;
  height: 65%; */
  /* box-shadow: 0 0 5px 2px #ff8dc9; */
  /* height: 75%; */
}

.featuredPosters img:hover {
  /* transform: scale(1.3); */
  /* box-shadow: 0 0 5px 10px #ff8dc9;
  transition: 0.5s ease;
  cursor: pointer; */
}

.searchTitle {
  color: #df0c62;
  font-weight: bold !important;
  letter-spacing: 4px !important;
}

.searchCountry {
  /* background-color: #df0c62; */
  position: relative;
}

.city {
  fill: #df0c62;
  rotate: 180deg;
  position: absolute;
  height: 700%;
  width: 700%;
  left: -264.5%;
  top: -185%;
  /* eslint-disable-next-line */
  z-index: -1;
}

.cityImage {
  width: 320px;
  height: 52px;
  object-fit: cover;
  transform: rotate(180deg);
  position: relative;
  float: right;
  right: -55px;
}

.eventsList {
  background-color: #fff0d7;
  height: 30rem;
  overflow-x: visible;
  overflow-y: scroll;
  padding: 48px;

  border-radius: 12px;
  position: relative;
}
.eventsList::-webkit-scrollbar {
  width: 5px;
}

.eventsList::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}

.eventListPoster {
  width: 100%;
  cursor: pointer;
}

.focused {
  transition: 0.3s ease-in-out;
  transform: scale(1.5);
  /* width: 75%; */
  box-shadow: -5px 0px 0px 0px #df0c62;
  margin-left: 5px;
  position: relative;
  z-index: 4;
}

.unfocused {
}

/* .smallTicket {
  width: 140px;
  height: 35px;
} */
.buyContainer {
  position: relative;
  width: fit-content;
  display: block;
  position: relative;
  flex: unset !important;
}
.buyContainer:hover,
.buyContainer:focus {
  /* opacity: 0.8; */
  scale: 0.9;
  transition: 0.1s ease;
  cursor: pointer;
}

.buyContainer:active {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);

  transition: 0.1s;
}

.buyText {
  font-family: "Phosphate" !important;
  color: #df0c62;
  text-align: center;
  position: absolute;
  width: fit-content;
  height: fit-content;
  left: 35px;
  top: 0;
  bottom: 0;
  margin: auto 0 !important;
}
.smallTicket {
  width: 140px;
  height: 40px;
}
.datesList {
  height: 10rem;
  overflow: scroll;
}

.datesList::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.datesList::-webkit-scrollbar-thumb {
  background-color: #df0c62;
  border-radius: 25px;
}

.datesList::-webkit-scrollbar-corner {
  background-color: transparent;
  border-radius: 25px;
}
