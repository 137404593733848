@font-face {
    font-family: "Phosphate";
    src: url("../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
  }

  .ticketPage{
    width: 21cm;
    height: 29.7cm;
    /* margin: 27mm 16mm 27mm 16mm; */
    border:1px solid black;
  }

  .ticket{
      /* padding: 20px 0 20px 0!important; */
      height:250px;
  }

  .barcode {
    transform: rotate(90deg);
    position: absolute;
    width: 250px;
    height:fit-content;
    overflow:hidden;
    top:90px;
    left:-50px;
  }

  .barcode svg{
    width:100%;
    height:100%;
  }

  .titleStroke {
    font-family: "Phosphate" !important;
    color: #df0c62;
  }

  .parentContainer {
    width: 100%;
    background-image: url("../../Assets/Images/ticketBackground.jpg");
    background-repeat: no-repeat;
    background-position: center;
  }

  .innerContainer {
    width: 100%;
    margin-bottom: 5%;
    background-color: white;
    position: relative;
    padding: 5%;
  }

  .leftBox1 {
    background-color: #fcf2e2;
    width: 50px;
    height: 150px;
    color: #df0c62;
    position: absolute;
    left: -5%;
    top: 2%;
    border: 1px solid #df0c62;
    box-shadow: 5px 0px 0px 0px #df0c62;
    transition: 0.5s;
  }

  .leftBox2 {
    background-color: #fcf2e2;
    width: 50px;
    height: 150px;
    color: #df0c62;
    position: absolute;
    left: -5%;
    top: 14.5%;
    border: 1px solid #df0c62;
    box-shadow: 5px 0px 0px 0px #df0c62;
    transition: 0.5s;
  }

  .leftBox3 {
    background-color: #fcf2e2;
    width: 50px;
    height: 150px;
    color: #df0c62;
    position: absolute;
    left: -5%;
    top: 28.5%;
    border: 1px solid #df0c62;
    box-shadow: 5px 0px 0px 0px #df0c62;
    transition: 0.5s;
  }

  .leftBox4 {
    background-color: #fcf2e2;
    width: 50px;
    height: 150px;
    color: #df0c62;
    position: absolute;
    left: -5%;
    top: 42.5%;
    border: 1px solid #df0c62;
    box-shadow: 5px 0px 0px 0px #df0c62;
    transition: 0.5s;
  }

  .ticket {
    background-image: url("../../Assets/Images/endTicket.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 7% 0% 7% 0%; */
  }

  .leftBox1:hover {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    transition: 0.5s;
  }
  .leftBox2:hover {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    transition: 0.5s;
  }
  .leftBox3:hover {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    transition: 0.5s;
  }
  .leftBox4:hover {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    transition: 0.5s;
  }

  .leftBox1Active {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    height: 150px;
    transition: 0.5s;
    position: absolute;
    left: -5%;
    top: 2%;
  }

  .leftBox2Active {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    height: 150px;
    transition: 0.5s;
    position: absolute;
    left: -5%;
    top: 14.5%;
  }

  .leftBox3Active {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    height: 150px;
    transition: 0.5s;
    position: absolute;
    left: -5%;
    top: 28.5%;
  }

  .leftBox4Active {
    cursor: pointer;
    box-shadow: none;
    color: white;
    font-weight: bold;
    background-color: #df0c62;
    width: 44px;
    height: 150px;
    transition: 0.5s;
    position: absolute;
    left: -5%;
    top: 42.5%;
  }



  .ticketDetails {
    position: relative;
    left: -2%;
  }

  .logo {
    width: 125%;
    transform: rotate(-90deg);
    position: relative;
    right: 50%;
  }

  .squaredSponsors{
    /* height: auto; */
    /* padding-bottom: 50%; */
    overflow: hidden;
    /* border: 1px solid; */
  }

  /* .imageBox {
    height: 650px;
    width: 650px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #ffb658;
    position: relative;
    overflow: hidden;
  }

  .number {
    position: absolute;
    bottom: 0%;
    left: 0%;
  } */
