

.soyIsim {width:117%;}
@media only screen and (max-width:991px){.soyIsim {width:118%;}}
/* input {
  padding: 16px;
  border: 2px solid #feb659;
  z-index: 1;
}

input::-webkit-input-placeholder {
  color: #df0c62;
}

.ePosta {
  padding: 16px;
  border: 2px solid #feb659;
  z-index: 1;
}

.ePosta::-webkit-input-placeholder {
  color: #df0c62;
}

.şifre {
  padding: 16px;
  border: 2px solid #feb659;
  z-index: 1;
}

.şifre::-webkit-input-placeholder {
  color: #df0c62;
}

.isim {
  padding: 16px;
  border: 2px solid #feb659;
  z-index: 1;
}

.isim::-webkit-input-placeholder {
  color: #df0c62;
}

.soyIsim {
  padding: 16px;
  border: 2px solid #feb659;
  z-index: 1;
}

.soyIsim::-webkit-input-placeholder {
  color: #df0c62;
} */
