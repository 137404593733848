@font-face {
  font-family: "Phosphate";
  src: url("../../../Assets/Fonts/Phosphate/Phosphate-inline.ttc");
}

.titleStroke {
  font-family: "Phosphate" !important;
  color: #df0c62;
}

input {
  padding: 8px;
}

.container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

.eventHeader {
  background-color: #ffd283;
  height: 350px;
  padding-top: 20%;
}

.eventPoster {
  width: 100%;
  box-shadow: -5px 0 0 0 #df0c62;
}

.eventDetails {
  height: 100%;
  padding: 5% 32px 32px;
}

.eventTicket {
  width: 40%;
  height: 35%;
}

.redDash {
  width: 100%;
  border-bottom: 2px dashed #df0c62;
}

.middleSection {
  margin-top: 14%;
  position: relative;
}
